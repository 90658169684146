import { EnumFilter, Field, Filter, FilterType, MeasurementFilter, MoneyFilter, NumberFilter, TextFilter } from "@notidar/api"
import { defaultFilterPerField } from "./shared.types";
import { AllFilters } from "../../utils";
import { notEmpty } from '../../utils'

export function areFilterEquals(filterLeft: Filter, filterRight: Filter): boolean {
  if (filterLeft.type !== filterRight.type) {
    return false;
  }

  if (filterLeft.field !== filterRight.field) {
    return false;
  }

  return true;
}

export function generateDefaultFilters(fields: Field[]): AllFilters[] {
  return fields
    .filter(field => field.searchable && field.indexed)
    .map(field => {
      const defaultFilter = defaultFilterPerField[field.type];
      if (defaultFilter) {
        return { ...defaultFilter, fieldType: field.type, field: field.name };
      }
    })
    .filter(notEmpty) as AllFilters[];
}

export function notEmptyFilter(filter: Filter): boolean {
  switch (filter.type) {
    case FilterType.Text:
      return !!(filter as TextFilter).query; // empty or undefined
    case FilterType.Enum:
      return ((filter as EnumFilter).enumIds?.length ?? 0) > 0;
    case FilterType.Number:
    case FilterType.Money:
    case FilterType.Measurement:
      {
        const numberFilter = filter as NumberFilter | MoneyFilter | MeasurementFilter;
        const result = (numberFilter.lowerLimit !== undefined && numberFilter.lowerLimit !== null) 
        || (numberFilter.upperLimit !== undefined && numberFilter.upperLimit !== null);
        return result;
      }
    default:
      return false
  }
}