import { notEmpty } from '../../utils'

export const toMeasurementNumber = (value: string | undefined, divider?: number, assumeLeftPart?: boolean): number | undefined => {
  if (!value) {
    return undefined;
  }

  const parts = value
    .split(' ')
    .map(part => parseInt(part.replace(/[^\d.-]/g, ''), 10))
    .filter(notEmpty)
    .filter(Number.isFinite)
    .filter(x => !Number.isNaN(x))
    .slice(0, 2);

  if (parts.length === 0) {
    return undefined;
  }

  let leftPart = parts[0];
  let rightPart = parts[1];

  if (assumeLeftPart !== true && (rightPart === undefined || divider === undefined)) {
    rightPart = leftPart;
    leftPart = 0;
  }

  const isRightNegative = rightPart < 0;
  const isLeftNegative = leftPart < 0 || isMinusZero(leftPart);
  const isTotalNegative = isLeftNegative || ((leftPart === 0 || leftPart === undefined) && isRightNegative);
  const rightSignMultiplier = isTotalNegative ? (isRightNegative ? 1 : -1) : (isRightNegative ? -1 : 1);

  const result = ((leftPart ?? 0) * (divider ?? 0)) + ((rightPart ?? 0) * rightSignMultiplier);

  if (result > Number.MAX_SAFE_INTEGER) {
    return Number.MAX_SAFE_INTEGER;
  }
  if (result < Number.MIN_SAFE_INTEGER) {
    return Number.MIN_SAFE_INTEGER;
  }
  return result;
}

export const isMinusZero = (value: number) => {
  return 1 / value === -Infinity;
}

export const toMeasurementString = (value: number | undefined, unit: string, divider?: number, subUnit?: string, full?: boolean): string | undefined => {
  if(value === undefined) {
    return undefined;
  }
  
  if (divider === undefined || subUnit === undefined) {
    return `${value}${unit}`;
  }

  const leftRaw = value / divider;
  const leftPart = leftRaw > 0 ? Math.floor(leftRaw) : Math.ceil(leftRaw);
  const rightPart = value % divider;

  if(full) {
    return `${isMinusZero(leftPart) ? '-' : ''}${leftPart}${unit} ${Math.abs(rightPart)}${subUnit}`;
  }

  if (leftPart === 0) {
    return `${rightPart}${subUnit}`;
  }
  else {
    if (rightPart === 0) {
      return `${leftPart}${unit}`;
    }
    else {
      return `${leftPart}${unit} ${Math.abs(rightPart)}${subUnit}`;
    }
  }
}