import { Dropdown, IDropdownOption, Stack } from '@fluentui/react'
import { EnumListValue, EnumValue, FieldType } from '@notidar/api'
import { useState } from 'react'
import { ValueEditorProps } from '../Shared.types'
import { ItemHeader } from '../shared/ItemHeader'
import { notEmpty } from '../../utils'
import { useTranslation } from 'react-i18next'

export interface EnumValueEditorProps extends ValueEditorProps<FieldType.Enum | FieldType.EnumList> { }

export const EnumValueEditor = ({ field, value, onUpdate, onValidation }: EnumValueEditorProps) => {
  const { t } = useTranslation();
  const [newValue, setNewValue] = useState<string[]>(() => {
    if (field.type === FieldType.Enum) {
      return [(value as EnumValue)?.enumPayload?.enumId].filter(notEmpty) ?? []
    }
    if (field.type === FieldType.EnumList) {
      return (value as EnumListValue)?.enumListPayload?.enumIds ?? []
    };
    return [];
  })

  const allowedEnums = field?.possibleValues.map(x => ({ key: x.enumId, text: x.displayString }))

  const onValueChange = (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption, index?: number): void => {
    if (option) {
      let newData: string[]
      if (field.type === FieldType.EnumList) {
        newData = option.selected ? [...newValue, option.key as string] : newValue.filter(key => key !== option.key)
      } else if (field.type === FieldType.Enum) {
        newData = [option.key as string]
      } else {
        newData = []
      }

      setNewValue(newData)

      if (field.type === FieldType.EnumList) {
        onUpdate(field, {
          type: FieldType.EnumList,
          enumListPayload: {
            enumIds: newData
          },
        } as EnumListValue)
      } else if (field.type === FieldType.Enum) {
        onUpdate(field, {
          type: FieldType.Enum,
          enumPayload: {
            enumId: newData[0]
          }
        } as EnumValue)
      }
      onValidation(field, true)
    }
  }

  return (
    <Stack>
      <ItemHeader name={field.name} title={field.displayName} />
      <Stack>
        <Dropdown
          multiSelect={field.type === FieldType.EnumList}
          disabled={allowedEnums.length === 0}
          placeholder={allowedEnums.length === 0 ? t("content.fields.enum.options_not_available") : t("content.fields.enum.options_placeholder")}
          options={allowedEnums}
          selectedKeys={field.type === FieldType.EnumList ? newValue : undefined}
          selectedKey={field.type === FieldType.Enum && newValue.length > 0 ? newValue[0] : undefined}
          onChange={onValueChange}
        />
      </Stack>
    </Stack>
  )
}
