import { IconType, PropertiesPostSection, PropertiesPostSectionType } from '@notidar/api'
import { SectionComponentProps } from '../../SectionComponent'
import { SectionContainer } from '../../SectionContainer'
import { getTypedValueFieldByFieldKey } from '../../../utils'
import { PropertiesSectionTitleComponent } from './PropertiesSectionTitleComponent'
import { PropertiesSectionItemsComponent } from './PropertiesSectionItemsComponent'
import { notEmpty } from '../../../utils'
import { allowedFieldTypes } from '../editor/CreatePropertyItemForm'

export interface PropertiesSectionComponentProps extends SectionComponentProps<PropertiesPostSection> {}

export const PropertiesSectionComponent = (props: PropertiesSectionComponentProps): JSX.Element | null => {
  if (props.section === undefined) {
    return null
  }

  const items =
    props.section?.items
      ?.map(item => {
        const { field, value } = getTypedValueFieldByFieldKey(props, item.propertyField) ?? {}
        if (field !== undefined && allowedFieldTypes.includes(field.type) && value !== undefined) {
          return { field, value, icon: item.icon ?? IconType.RadioBullet }
        }
      })
      .filter(notEmpty) ?? [];

  if (items.length === 0) {
    return null
  }

  return (
    <SectionContainer>
      <PropertiesSectionTitleComponent title={props.section.titleString} />
      <PropertiesSectionItemsComponent items={items} displayType={props.section.displayType ?? PropertiesPostSectionType.Icons} />
    </SectionContainer>
  )
}
