import { DefaultButton, List, Stack } from '@fluentui/react'
import { FieldType, ImageFileListValue, Api } from '@notidar/api'
import { ItemHeader } from '../shared/ItemHeader'
import { ValueEditorProps } from '../Shared.types'
import { useContext, useState } from 'react'
import { UploadImageFileButton } from './UploadImageFileButton'
import { ChannelId } from '../../models'
import { ImageFileValueEditorItem } from './ImageFileValueEditorItem'
import { PostId } from '../../models'
import { optimizedImageFileUrlFromId, getFileType } from '../../utils'
import { AppContentContext } from '../../context'
import { notEmpty } from '../../utils'
import { useTranslation } from 'react-i18next'

declare global {
  var NotidarApiClient: Api<unknown>;
}

// using global variable to pass the ApiClient to the component
const ApiClient = (globalThis.NotidarApiClient).api;

export interface ImageFileValueEditorProps
  extends ValueEditorProps<FieldType.ImageFileList> {
  channelId: ChannelId
  postId?: PostId
}

export interface ImageItem {
  uploadId?: number
  file?: File
  fileId?: string
  isUploading?: boolean
  errorMessage?: string
}

export const ImageFileValueEditor = ({
  field,
  channelId,
  postId,
  value,
  onUpdate,
  onValidation,
}: ImageFileValueEditorProps) => {
  const { t } = useTranslation();
  const { mediaBaseUrl } = useContext(AppContentContext)
  const [latestUploadId, setUploadId] = useState<number>(0)
  const [images, setImages] = useState<ImageItem[]>(() => {
    return ((value as ImageFileListValue)?.imageFileListPayload?.fileIds ?? [])
      .filter(notEmpty)
      .map(x => ({ fileId: x }))
  })

  const addImages = (imagesToAdd: File[]): void => {
    setImages(images => [...images, ...uploadImages(imagesToAdd)])
  }

  const clearImages = (): void => {
    setImages([])
    onImagesUpdated([])
  }

  const removeImage = (index: number): void => {
    let imagesToSet: ImageItem[] = []

    setImages(images => {
      imagesToSet = [...images]
      imagesToSet.splice(index, 1)
      return imagesToSet
    })

    onImagesUpdated(imagesToSet)
  }

  const uploadImages = (imagesToUpload: File[]): ImageItem[] => {
    let uploadId: number = latestUploadId
    setUploadId(x => x + imagesToUpload.length)
    return imagesToUpload.map((image, index) => {
      const currentUploadId = uploadId + index
      ApiClient.createFileAsync(channelId, { File: image, Type: getFileType(image.type) }).then(
        success => {
          const fileId = success.data.file?.fileId
          if (fileId) {
            updateImage(currentUploadId, x => ({
              ...x,
              isUploading: false,
              errorMessage: undefined,
              fileId: fileId,
            }))
          } else {
            updateImage(currentUploadId, x => ({
              ...x,
              isUploading: false,
              errorMessage: t("content.fields.image.upload_failed"),
            }))
          }
        },
        () => {
          updateImage(currentUploadId, x => ({
            ...x,
            isUploading: false,
            errorMessage: t("content.fields.image.upload_failed"),
          }))
        }
      )

      return {
        uploadId: currentUploadId,
        file: image,
        isUploading: true,
      }
    })
  }

  const updateImage = (uploadId: number, updateAction: (item: ImageItem) => ImageItem): void => {
    let imagesToSet: ImageItem[] = []

    setImages(images => {
      imagesToSet = images
      const index = images.findIndex(x => x.uploadId === uploadId)
      if (index !== undefined && index !== -1) {
        const newImages = [...images]
        newImages[index] = updateAction(newImages[index])
        imagesToSet = newImages
      }
      return imagesToSet
    })

    onImagesUpdated(imagesToSet)
  }

  const onImagesUpdated = (images: ImageItem[]): void => {
    onUpdate(field, {
      type: FieldType.ImageFileList,
      imageFileListPayload: { fileIds: images.map(x => x.fileId).filter(notEmpty) }
    })
    onValidation(field, !images.some(x => x.isUploading || x.errorMessage))
  }

  const onRenderCell = (
    item?: ImageItem,
    index?: number,
    isScrolling?: boolean
  ): JSX.Element | null => {
    if (!item || index === undefined) {
      return null
    }

    let imageUrl: string;
    if (item.fileId) {
      imageUrl = optimizedImageFileUrlFromId(mediaBaseUrl, item.fileId)
    } else if (item.file) {
      imageUrl = URL.createObjectURL(item.file)
    } else {
      imageUrl = ''
    }

    return (
      <ImageFileValueEditorItem
        imageUrl={imageUrl}
        errorMessage={item.errorMessage}
        isUploading={item.isUploading}
        onRemove={() => removeImage(index)}
      />
    )
  }

  return (
    <Stack>
      <ItemHeader name={field.name} title={field.displayName} />
      <List items={images} onRenderCell={onRenderCell} />
      <Stack tokens={{ childrenGap: 10 }} horizontal wrap>
        <UploadImageFileButton
          allowMultiple={field.type === FieldType.ImageFileList}
          forceCamera={false}
          onImagesAdded={addImages}
        />
        {images.length > 0 && field.type === FieldType.ImageFileList && (
          <DefaultButton style={{ margin: '10px 5px' }} onClick={clearImages} text={t("content.fields.image.remove_all")} />
        )}
      </Stack>
    </Stack>
  )
}
