import { Stack } from '@fluentui/react'
import { Tag } from './Tag'
import { EnumListField, EnumField, FieldType, EnumListValue, StringListValue, StringValue, EnumValue, Field, TagsPostSection } from '@notidar/api'
import { SectionComponentProps } from '../SectionComponent'
import { SectionContainer } from '../SectionContainer'
import { parseFieldValue } from '../../utils'
import { notEmpty } from '../../utils'

export interface TagsSectionComponentProps
  extends SectionComponentProps<TagsPostSection> {}

export const TagsSectionComponent = (props: TagsSectionComponentProps) => {
  const tags = parseFieldValue<FieldType.String | FieldType.StringList | FieldType.Enum | FieldType.EnumList, string[]>(
    props,
    props.section.tagsField,
    {
      String: (value: StringValue) => { return value.stringPayload?.value ? [value.stringPayload?.value] : [] },
      StringList: (value: StringListValue) => { return value.stringListPayload?.values ?? [] },
      Enum: (value: EnumValue, field: Field) => {
        const map = (field as EnumField).possibleValues.reduce((x, tag) => {
          x[tag.enumId] = tag.displayString
          return x
        }, {} as Record<string, string>)
        const displayValue = value.enumPayload?.enumId ? map[value.enumPayload.enumId] : undefined
        return displayValue ? [displayValue] : []
      },
      EnumList: (value: EnumListValue, field: Field) => {
        const map = (field as EnumListField).possibleValues.reduce((x, tag) => {
          x[tag.enumId] = tag.displayString
          return x
        }, {} as Record<string, string>)
        return value.enumListPayload?.enumIds?.map(x => map[x]).filter(notEmpty) ?? []
      },
    }) ?? []

  return (
    <SectionContainer>
      <Stack horizontal horizontalAlign='start' wrap tokens={{ childrenGap: 10 }}>
        {tags && tags.map((tag, index) => <Tag key={index} value={tag} />)}
      </Stack>
    </SectionContainer>
  )
}
