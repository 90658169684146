import { Label } from "@fluentui/react"
import { AllFields, AllFilters } from "@notidar/content/src/utils"
import { FilterComponent, notEmpty } from "@notidar/content"

export interface ChannelFilterListProps {
  fields: AllFields[]
  filters: AllFilters[]
  onSubmit: (filter: AllFilters) => void
}

export const ChannelFilterList = ({ fields, filters, onSubmit }: ChannelFilterListProps) => {
  const filterComponents = filters
    .map((filter, index) =>
      <div key={index}>
        {FilterComponent({
          fields,
          filter,
          onSubmit: onSubmit
        })}
      </div>)
    .filter(notEmpty)

  if (filterComponents.length === 0) {
    return (
      <div>
        <Label>{"No filters available."}</Label>
      </div>
    )
  } else {
    return (
      <div>
        {filterComponents}
      </div>
    )
  }
}